import React from "react";
import { Row, Col } from "react-bootstrap";
import { FaAngleRight, FaAngleLeft } from "react-icons/fa";

import { Box } from "../../components/Core";
import PostCard from "../../components/PostCard";
import Pagination, { PageItem } from "../../components/Pagination";

import imgVehicles from "../../assets/image/jpeg/support-vehicles.jpg";
import imgContracts from "../../assets/image/jpeg/support-contracts.jpg";
import imgCustomers from "../../assets/image/jpeg/support-customers.jpg";

const VehiclesListSidebar = () => (
  <>
    {/* <!-- Blog section --> */}

    <Row className="align-items-center justify-content-center">
      <Col lg="12" className="mb-5">
        <PostCard
          horizontal
          img={imgVehicles}
          preTitle="Feb 14, 2022"
          title="Add A New Vehicle To LoanerHub"
          readMore
          link={"/support-add-vehicle"}
        >
          With LoanerHub, you can add all your vehicles without any extra fees.
          See how easy it is to add your first vehicle here.
        </PostCard>
      </Col>

      <Col lg="12" className="mb-5">
        <PostCard
          horizontal
          img={imgVehicles}
          preTitle="Feb 14, 2022"
          title="Update A Vehicle's Image"
          readMore
          link={"/support-add-vehicle-image"}
        >
          With LoanerHub, you can add an image of a Vehicle to help distinguish
          if from the others.
        </PostCard>
      </Col>

      <Col lg="12" className="mb-5">
        <PostCard
          horizontal
          img={imgVehicles}
          preTitle="Feb 14, 2022"
          title="Vehicle Status"
          readMore
          link={"/support-update-vehicle-status"}
        >
          What is a vehicle status and how do I update it?
        </PostCard>
      </Col>

      <Col lg="12" className="mb-5">
        <PostCard
          horizontal
          img={imgVehicles}
          preTitle="Feb 14, 2022"
          title="Add A Note For A Vehicle"
          readMore
          link={"/support-add-vehicle-note"}
        >
          Want to add a little extra info to a vehicle?
        </PostCard>
      </Col>
    </Row>
  </>
);

export default VehiclesListSidebar;
